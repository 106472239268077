import React from 'react';
import cx from 'classnames';
import dynamic from 'next/dynamic';

import styles from './index.module.scss';

const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));
const CardLearnings = dynamic(() => import('#app/UI/organisms/card-learnings'));

const dataLearningPrograms = [
  {
    title: 'Desarrollo de habilidades digitales para todas las edades',
    description:
      'Ofrecemos cursos en tecnología, programación e inteligencia artificial, con opciones de corta y larga duración sin necesidad de conocimientos previos.',
    srcImg: '/home/learning-programs/card-1.webp'
  },
  {
    title: 'Programas para colegios',
    description:
      'Diseñamos programas curriculares y extracurriculares formando niños y adolescentes en habilidades digitales y socio-ocupacionales.',
    srcImg: '/home/learning-programs/card-2.webp'
  },
  {
    title: 'Programas de formación docente',
    description:
      'Capacitamos docentes en enseñanza de habilidades digitales para llevar la educación en tecnología a todos los rincones de LATAM y dejar capacidad instalada.',
    srcImg: '/home/learning-programs/card-3.webp'
  },
  {
    title: 'Capacitaciones para empresas',
    description:
      'Ofrecemos formación especializada en habilidades digitales a colaboradores de empresas y sus familias.',
    srcImg: '/home/learning-programs/card-4.webp'
  }
];

const LearningPrograms = () => {
  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <Typography className={cx(styles.title)} tag="h2">
          Desarrollamos programas de aprendizaje con el objetivo de{' '}
          <span className={styles.mainColor}> generar autonomía ocupacional</span>
        </Typography>
        <Typography className={cx(styles.subtitle)} tag="p">
          Únete a nosotros en esta misión de transformación educativa.
        </Typography>
      </div>

      <div className={styles.containerCards}>
        {dataLearningPrograms.map((program, idx) => (
          <CardLearnings {...program} key={`learning-programs-${idx}`} />
        ))}
      </div>
    </section>
  );
};

export default LearningPrograms;
